import React, { useEffect, useState } from 'react';
import { getApiUrl, getAppVersion } from '../helpers/lookup';
import { Spinner } from 'reactstrap';

function Home() {

    const [loading, setLoading] = useState(true);
    const [welcomeMessage, setWelcomeMessage] = useState("");

    useEffect(() => {
        loadMessage();
    }, [welcomeMessage]);

    const loadMessage = async () => {
        try {
            const response = await fetch(
                `${getApiUrl()}/pagecontents/GetBySlug/welcome-page`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setWelcomeMessage(data.copy);
                setLoading(false);
            } else {
                console.log(response.status + " :: " + response.statusText);
                setWelcomeMessage("<h1>Welcome to E.S.T Africa</h1>");
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setWelcomeMessage("<h1>Welcome to E.S.T Africa</h1>");
            setLoading(false);
        }
    };

    const renderMessage = () => {
        return <div dangerouslySetInnerHTML={{ __html: welcomeMessage }}></div>;
    };

    const content = loading ? (
        <em>
            <Spinner /> Loading...
        </em>
    ) : (
        renderMessage()
    );

    return (
        <div className='pb-5'>
            <div className="d-flex justify-content-center">{content}</div>

            <div className="d-flex flex-column align-items-center mb-3">
                <a type="button" className="btn btn-danger mb-3 w-75" href="/login">
                    Login
                </a>
                {/* OR
                <a
                    type="button"
                    className="btn btn-purple mb-3 mt-3 w-75"
                    href="/register"
                >
                    Register
                </a> */}
                <a
                    href="https://estafrica.co.za/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-purple small"
                >
                    Privacy Policy
                </a>
            </div>
            <div className="mt-3 text-purple  text-center">Version: {getAppVersion()}</div>
        </div>
    )
}

export default Home;