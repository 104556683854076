/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { getApiUrl } from '../../helpers/lookup';
import { IsAuthenticated, getAuthToken } from '../../helpers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ScanResult() {
  const [showThrobble, setShowThrobble] = useState(false);
  const [throbbleMessage, setThrobbleMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      IsAuthenticated().then(res => {
        if (res)
          saveData();
      });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const setThrobble = (displayThrobble, throbbleMsg = "") => {
    setThrobbleMessage(throbbleMsg);
    setShowThrobble(displayThrobble);
  };

  const saveData = async () => {
    setThrobble(true, "Checking QR Code...");

    try {
      const data = location.state.code;
      const bearer = `Bearer ${getAuthToken()}`;

      const response = await fetch(
        `${getApiUrl()}/visitslog?slug=${data}`,
        {
          method: "POST",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();

        setThrobble(false);
        const swalOptions = {
          title: "Scanned Success!",
          text: `You have scanned ${result}'s QR Code. Would you like to scan another QR Code?`,
          icon: "success",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          cancelButtonText: "No",
          cancelButtonAriaLabel: "No",
        };

        Swal.fire(swalOptions).then(async (value) => {
          if (value.isConfirmed) {
            navigate("/qrscanner");
          } else {
            navigate("/");
          }
        });
      } else {
        setThrobble(false);

        console.log(response.status + ": " + response.statusText);

        if (response.status === 409) {
          Swal
            .fire(
              "Oops!",
              "You've already scanned this QR code.",
              "warning"
            )
            .then(async () => {
              navigate("/qrscanner");
            });
        }
        if (response.status === 400) {
          Swal
            .fire(
              "Oops!",
              "Competition Ended: The QR code you scanned is no longer valid. Thank you for your participation!",
              "warning"
            )
            .then(async (value) => {
              if (value) {
                navigate("/");
              }
            });
        }
      }
    } catch (error) {
      console.error("Error :: ", error);
    }
  };

  return (
    <div>
      <ThrobbleHelper
        showThrobble={showThrobble}
        throbbleMessage={throbbleMessage}
      />
    </div>
  );
}
