/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import ThrobbleHelper from '../../css/ThrobbleHelper';
import QrScanner from 'qr-scanner';

export default function QRScanner() {
  const [result, setResult] = useState("No Result");
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState();

  const [showThrobble, setShowThrobble] = useState(false);
  const [throbbleMessage, setThrobbleMessage] = useState("");
  const navigate = useNavigate();

  let qrScannerObj = null;

  useEffect(() => {
    InitQRScanner();
    return () => {
      qrScannerObj?.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InitQRScanner = async () => {
    setThrobble(true, "Getting Camera Access");
    if (videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        (result) => handleResult(result),
        {
          highlightScanRegion: true,
          maxScansPerSecond: 1,
        }
      );

      scanner
        .start()
        .then(() => {
          qrScannerObj = scanner;
          setQrScanner(scanner);
          setThrobble(false);
        })
        .catch(() => {
          setThrobble(false);
          swal(
            "Camera Access",
            "Camera access has been denied. To enable camera permission, please open your browser settings and navigate to the 'Privacy' or 'Permissions' section. Locate the camera permissions and ensure it is set to 'Allow' or 'Ask'.",
            "error"
          ).then(async () => {
            navigate("/");
          });
        });
    }
  };

  const handleResult = (result) => {
    if (result.data) {
      navigate("/scan-result", { state: { code: result.data } });
    }
  };

  const setThrobble = (displayThrobble, throbbleMsg = "") => {
    setThrobbleMessage(throbbleMsg);
    setShowThrobble(displayThrobble);
  };

  return (
    <div className="qr-block">
      <div className="qrContainer">
        <video id="qrVideo" className="img-fluid" ref={videoRef}></video>
      </div>
      <p className="text-center mb-3">{result === "No Result" ? "" : result}</p>
      <ThrobbleHelper
        showThrobble={showThrobble}
        throbbleMessage={throbbleMessage}
      />
    </div>
  );
}
