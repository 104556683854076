/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
  render() {
    return (
      <header className="pt-1 pb-2 fixed-top">
        <div className="container text-center">
          <Link to={"/"}>
            <img src="/assets/EST-Africa-Logo.png" className="img-fluid " alt="E.S.T Africa"/>
          </Link>
        </div>
      </header>
    );
  }
}
